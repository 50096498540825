import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Skeleton,
  Spacer,
  Square,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';
import { faFileExport, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flag from '../resources/step/flag.svg';
import bookie from '../resources/step/bookie.svg';
import export_icon from '../resources/admin/export_icon.svg';
import students_icon from '../resources/admin/students_icon.svg';
import { FaChevronDown } from 'react-icons/fa';
import { StudentProfile } from './StudentProfile';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ICourse, IDowntime, IUser } from '../types';
import { getCourseStudents, getMyCourses } from '../API/course';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { cursorTo } from 'readline';
import { FaCrown } from 'react-icons/fa';
import './Admin.css';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { loadCldr } from '@syncfusion/ej2-base';
import { L10n } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import moment from 'moment';
import { getDowntime, setDowntime } from '../API/userData';

const Admin: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const drawerDisclosure = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [downtimeval, setDowntimeval] = useState<IDowntime|null>();
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const reasonRef = React.useRef<HTMLTextAreaElement>(null);
    const toast = useToast();

    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
    L10n.load({
      'fr': {
      'datetimepicker': {
          placeholder: 'Date et heure',
          today:"aujourd'hui"
      }
    }});
    
    // Middleware
    const { user } = useUser();
    useEffect(() => {
      if (!user || user.rank != 'admin') {
        navigate('/');
      }
    }, []);

    // Get system data
    useEffect(() => {
      fetchDowntime();
    }, []);

    const fetchDowntime = () => {
      getDowntime().then((res) => {
        setDowntimeval(res.data);
      });
    }

    const handleStartDateChange = (e: any) => {
      setStartDate(e.value);
    }

    const handleEndDateChange = (e: any) => {
      setEndDate(e.value);
    }

    const enableMaintenance = () => {
      // Check if the format of the dates is correct (not if start is before end, just the FORMAT)
      if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
        toast({
          title: "Erreur",
          description: "Veuillez entrer des dates valides.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Check if there is a reason
      if (!reasonRef.current?.value) {
        toast({
          title: "Erreur",
          description: "Veuillez entrer une raison pour la maintenance.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      
      setDowntime({
        start: startDate,
        end: endDate,
        reason: reasonRef.current?.value || "Maintenance"
      }).then(() => {
        toast({
          title: "Maintenance activée",
          description: "La maintenance a été activée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchDowntime();
      } ).catch((err) => {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de l'activation de la maintenance.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } );
    }

    const disableMaintenance = () => {
      setDowntime({
        start: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
        end: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
        reason: "No downtime."
      }).then(() => {
        toast({
          title: "Maintenance désactivée",
          description: "La maintenance a été désactivée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchDowntime();
      } ).catch((err) => {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la désactivation de la maintenance.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } );
    }
  
    return (<>
      {user && user.rank == "admin" && <Box minH="100vh" bg={"white"}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        
        <Box ml={{ base: 0, md: 60 }} p="10" color={"#4b4b4b"}>
          <Flex>
              <Text mb={10} fontSize={"3xl"} fontWeight={800} cursor={'default'}>Gestion de la plateforme</Text>
            <Spacer />
          </Flex>


          <Tabs colorScheme='red'>
            <TabList borderBottomWidth={'2px'}>
              <Tab fontWeight={700}>Paramètres généraux</Tab>
              <Tab fontWeight={700}>Gestion des cours</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Text mb={3} fontSize={"2xl"} fontWeight={800} cursor={'default'}>Maintenance</Text>

                {downtimeval && moment(downtimeval.start).isBefore(new Date()) && moment(downtimeval.end).isAfter(new Date()) &&
                  <Box bgColor={'red.500'} p={5} rounded={'xl'}>
                    <Center>
                      <Text cursor={'default'} fontSize={'lg'} fontWeight={800} color={'white'}>Le site est actuellement en maintenance</Text>
                    </Center>
                  </Box>
                }


                {(!downtimeval || (downtimeval && moment(downtimeval.end).isBefore(new Date()))) &&
                  <Box>
                    <FormControl>
                      <FormLabel fontWeight={700}>Raison de la maintenance (message public)</FormLabel>
                      <Textarea 
                        placeholder="Raison de la maintenance"
                        ref={reasonRef}
                        maxLength={500}
                      />
                    </FormControl>

                    <HStack mt={3}>
                      <FormControl width={'300px'}>
                        <FormLabel fontWeight={700}>Début de maintenance</FormLabel>
                          <Box width={'300px'}>
                            <DateTimePickerComponent 
                              locale='fr' 
                              id="datetimepicker" 
                              placeholder="Date et heure"
                              value={startDate}
                              change={handleStartDateChange}
                            />
                          </Box>
                      </FormControl>

                      <FormControl width={'300px'}>
                        <FormLabel fontWeight={700}>Fin de maintenance</FormLabel>
                          <Box width={'300px'}>
                            <DateTimePickerComponent 
                              locale='fr' 
                              id="datetimepicker" 
                              placeholder="Date et heure"
                              value={endDate}
                              change={handleEndDateChange}
                            />
                          </Box>
                      </FormControl>

                      <Spacer />

                      <Button fontWeight={800} size={'sm'} colorScheme="red" onClick={enableMaintenance}>Activer la maintenance</Button>
                    </HStack>
                  </Box>
                }

                  {downtimeval && moment(downtimeval.end).isAfter(new Date()) &&
                    <Flex mt={4}>
                      <Box>
                        <Text fontWeight={700}>
                          Maintenance prévue du {moment(downtimeval.start).format('DD/MM/YYYY HH:mm')} au {moment(downtimeval.end).format('DD/MM/YYYY HH:mm')}
                        </Text>
                        <Text fontWeight={600}>
                          Raison : "{downtimeval.reason}"
                        </Text>
                      </Box>
                      <Spacer />
                      <Button mt={2} p={4} fontWeight={800} size={'sm'} colorScheme="green" onClick={disableMaintenance}>Désactiver la maintenance</Button>
                    </Flex>
                  }
              </TabPanel>

              <TabPanel>
                <Box border={'2px dashed'} borderColor={'red.500'} rounded={'xl'} p={10} width={'100%'}>
                  <Center>
                    <Text cursor={'default'} fontWeight={700} fontSize={'xl'} color={'red.500'}>Zone en construction</Text>
                  </Center>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>

        </Box>
      </Box>
      }
    </>);
  }
  export default Admin;