import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./fonts/DINRoundPro.ttf";
import "./fonts/DINRoundPro-Medi.ttf";
import "./fonts/DINRoundPro-Bold.ttf";
import "./fonts/DINRoundPro-Black.ttf";
import "./fonts/DINRoundPro-Light.ttf";
import "hover.css";
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import ProgressTracker from './ProgressTracker/ProgressTracker';
import Lesson from './Lesson';
import Students from './admin/Students';
import Challenges from './admin/Challenges';
import Courses from './admin/Courses';
import { registerLicense } from '@syncfusion/ej2-base';
import { UserProvider, useUser } from './UserContext';
import Cookies from 'js-cookie';
import { TabProvider } from './TabContext';
import Login from './Login';
import SetUser from './SetUser';
import Tuto from './ProgressTracker/Tuto';
import Admin from './admin/Admin';
import Downtime from './Downtime';

if (process.env.REACT_APP_SYNCFUSION_LICENSE_KEY){
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);
}
else
  console.error("No Syncfusion license key found.");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const InitializeOnce = () => {
  useEffect(() => {
    // You can add any initialization logic here, such as API calls, global state setup, etc.
  }, []);

  return null; // or you can return some UI if necessary
};

const App = () => {
  const { user, setUser } = useUser();
  const [tokenError, setTokenError] = useState(true);

  useEffect(() => {
    const matToken = Cookies.get('jwt_cafe');
    if (matToken) {
      setTokenError(false);
    }
    else {
      setTokenError(true);
    }
    
    const tokenErrorListener = (e: any) => {
      console.log("TOKEN_ERROR received");
      setTokenError(true);
    }
    document.addEventListener("TOKEN_ERROR", tokenErrorListener);
}, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
          <InitializeOnce />
          {tokenError &&
            <Login />
          }
          {!tokenError && <>
            {user && <Routes>
              <Route index element={<ProgressTracker />} />
              <Route path="/admin/challenges" element={<Challenges />} />
              <Route path="/admin/courses" element={<Courses />} />
              <Route path="/lesson/:moduleSlug/:stepIndex" element={<Lesson />} />
              <Route path="/admin/students" element={<Students />} />
              <Route path="/admin/website" element={<Admin />} />
              <Route path="/tuto" element={<Tuto />} />
              <Route path="/down" element={<Downtime />} />
              {/* DEFAULT ROUTE */}
              <Route path="*" element={<ProgressTracker />} />
              </Routes>
            }
            {!user && <SetUser />}
          </>}
      </BrowserRouter>
    </ChakraProvider>
  );
};

const brandPrimary = defineStyle({
  background: 'white',
  color: '#168bc3',
  fontWeight: 700,

  // let's also provide dark mode alternatives
  _dark: {
    background: 'orange.300',
    color: 'orange.800',
  }
})

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary },
})

const theme = extendTheme({
  // styles: {
  //   global: {
  //   },
  // },
  fonts: {
    body: `'Nunito', sans-serif`,
    heading: `'Nunito', sans-serif`,
  },
  colors: {
    gray: {
      900: '#212427'
    }
  },
  components: {
    Button: buttonTheme
  }
});

root.render(
  <UserProvider initialUser={{rank:"user", firstname:"", lastname:"", matricule:""}}>
    <TabProvider>
      <App />
    </TabProvider>
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
