import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
  } from 'react-icons/fi';
import { IconType } from "react-icons";
import { BiSolidTrophy, BiStats } from "react-icons/bi";
import { FaBook, FaCode, FaHouseUser } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { PiStudentBold } from "react-icons/pi";


interface LinkItemProps {
    name: string;
    path: string;
    icon: IconType;
    external?: boolean;
}
export const LinkItems: Array<LinkItemProps> = [
    { name: 'Étudiant·e·s', icon: PiStudentBold, path: '/admin/students' },
    // { name: 'Challenges', icon: FaCode, path: '/admin/challenges' },
    { name: 'Gérer mes cours', icon: FaBook, path: '/admin/courses' },
    { name: 'Administration', icon: RiAdminFill, path: '/admin/website' },
    { name: 'Progress Tracker', icon: FaMapMarkedAlt , path: '/', external: true },
    // { name: 'Favourites', icon: FiStar },
    // { name: 'Settings', icon: FiSettings },
];