// Static types for the application
export interface IModule {
    _id: string;
    course: string; // course code
    title: string;
    steps: IStep[]; // step ids
    published_at: Date;
    happyhour_end: Date;
    slug: string;
    createdAt: Date;
}

export enum StepType {
    EXERCISE = "exercise",
    REWARD = "reward"
}

export enum StepState {
    LOCKED = "locked",
    UNLOCKED = "unlocked",
    COMPLETED = "completed",
}

export interface IStep {
    _id: string;
    title?: string;
    description?: string;
    type: StepType;
    pool?: string[]; // exercise ids
    questions_nb?: number;
    reward: number;
    state?: StepState;
    in_progress?: boolean;
    progress_deadline?: Date;
}

export enum ExerciseType {
    MCQ = "mcq"
}

export interface IExercise {
    _id: string;
    type: ExerciseType;
    content: any;
}

// Dynamic types for the application
export interface IStepProgress {
    stepId: string;
    matricule: string;
    completed: boolean;
    answers: string[]; // answers ids
}

export interface IExerciseAnswer {
    _id: string;
    matricule: string;
    relatedExercise: string; // exercise id
    content: any;
    correct: boolean;
}

export interface IUser {
    matricule: string;
    firstname: string;
    lastname: string;
    mail: string;
    rank: string;
    diamonds: number;
    xp: number;
    courses: string[]; // course codes
}

export interface ICourse {
    teachers: string[]; // teacher id
    students: string[]; // student ids
    title: string;
    code: string;
}

// Choice for a MCQ question
export interface AnswerMCQ {
    originalIndex?: number;
    content: string;
    correct: boolean;
    feedback: string;
}

export interface IMcq {
    _id: string;
    statement: string;
    code?: string;
    answers: AnswerMCQ[];
}

export interface IStepInstance {
    _id: string;
    matricule: string;
    step_id: string; // step id
    questions: IMcq[]; // question documents
    answers: any[]; // dictionary of answers
    deadline: Date;
    finished: boolean;
}

// Answer introduced by the student
export interface IStepAnswer {
    question_id: string; // question id
    answer_index: number;
    correct: boolean;
}

export interface IEarnings {
    diamonds: number;
    xp: number;
    summary: {
        statement: string;
        correct: boolean;
        diamonds: number;
        xp: number;
        previouslyCorrect: boolean;
    }[];
    happyhour: boolean;
    firstTime: number;
}

export interface IChapter {
    _id: string;
    course: string; // course code
    title: string;
    sections: string[]; // section ids
}

export interface ISection {
    _id: string;
    title: string;
    questions: string[]; // MCQ ids
}

export enum LogAction {
    OPEN_PAGE = "open_page",
    SUBMIT_ANSWER = "submit_answer",
    NEXT_STEP = "next_step",
    COMPLETE_STEP = "complete_step",
    NEXT_TUTORIAL = "next_tutorial",
    BACK_TUTORIAL = "back_tutorial",
}

export interface IUserlog {
    tab_id: string;
    action: LogAction;
    content: any;
}

export interface IChallengeSubmissionSummary {
    date: Date;
    evaluative: boolean;
    happyHours: boolean;
    mark: number;
}

export interface IChallengeSummary {
    matricule: string;
    title: string;
    mark: number;
    joker: boolean;
    submissions: IChallengeSubmissionSummary[];
}

export interface IDowntime {
    start: Date;
    end: Date;
    reason: string;
}