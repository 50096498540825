import React, { useEffect } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  Grid,
  HStack,
  Image,
  Select,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';
import pen from '../resources/pen.svg';
import lightning from '../resources/lightning.svg';
import check_img from '../resources/check.svg';
import late_img from '../resources/late.svg';
import stats_img from '../resources/stats.svg';
import timespent_img from '../resources/timespent.svg';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Tooltip as RechartsTooltip } from 'recharts';
import ApexCharts from 'apexcharts';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';


const Challenges: React.FunctionComponent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const drawerDisclosure = useDisclosure();

    const challengeDistro = [
      {name: '0-1', students: 5},
      {name: '1-2', students: 2},
      {name: '2-3', students: 1},
      {name: '3-4', students: 5},
      {name: '4-5', students: 4},
      {name: '5-6', students: 0},
      {name: '6-7', students: 0},
      {name: '7-8', students: 2},
      {name: '8-9', students: 8},
      {name: '9-10', students: 10},
      {name: '10-11', students: 25},
      {name: '11-12', students: 15},
      {name: '12-13', students: 22},
      {name: '13-14', students: 20},
      {name: '14-15', students: 12},
      {name: '15-16', students: 11},
      {name: '16-17', students: 10},
      {name: '17-18', students: 3},
      {name: '18-19', students: 5},
      {name: '19-20', students: 4},
    ];

    const challengesMarks = [
      {name: '0-1', c0: 1, c1: 1, c2: 2, c3: 0, c4: 2, c5: 0},
      {name: '1-2', c0: 2, c1: 0, c2: 0, c3: 1, c4: 2, c5: 0},
      {name: '2-3', c0: 1, c1: 1, c2: 0, c3: 1, c4: 0, c5: 1},
      {name: '3-4', c0: 0, c1: 1, c2: 1, c3: 1, c4: 0, c5: 1},
      {name: '4-5', c0: 1, c1: 0, c2: 1, c3: 1, c4: 0, c5: 1},
      {name: '5-6', c0: 13, c1: 13, c2: 15, c3: 9, c4: 7, c5: 13},
      {name: '6-7', c0: 14, c1: 19, c2: 14, c3: 10, c4: 7, c5: 14},
      {name: '7-8', c0: 18, c1: 10, c2: 12, c3: 14, c4: 12, c5: 17},
      {name: '8-9', c0: 16, c1: 9, c2: 15, c3: 16, c4: 13, c5: 10},
      {name: '9-10', c0: 17, c1: 10, c2: 12, c3: 15, c4: 17, c5: 18},
      {name: '10-11', c0: 12, c1: 17, c2: 13, c3: 14, c4: 12, c5: 17},
      {name: '11-12', c0: 7, c1: 11, c2: 17, c3: 15, c4: 18, c5: 17},
      {name: '12-13', c0: 12, c1: 17, c2: 17, c3: 10, c4: 13, c5: 11},
      {name: '13-14', c0: 18, c1: 19, c2: 19, c3: 10, c4: 15, c5: 18},
      {name: '14-15', c0: 12, c1: 16, c2: 10, c3: 15, c4: 14, c5: 14},
      {name: '15-16', c0: 1, c1: 1, c2: 2, c3: 1, c4: 0, c5: 1},
      {name: '16-17', c0: 1, c1: 2, c2: 1, c3: 1, c4: 0, c5: 0},
      {name: '17-18', c0: 0, c1: 2, c2: 1, c3: 2, c4: 1, c5: 1},
      {name: '18-19', c0: 1, c1: 3, c2: 3, c3: 0, c4: 0, c5: 0},
      {name: '19-20', c0: 0, c1: 2, c2: 2, c3: 2, c4: 1, c5: 1}
    ];

    useEffect(() => {
      const chartDiv = document.querySelector("#submissionChart");

      // Delete the content of the chart div
      if (chartDiv)
        while (chartDiv.firstChild) {
          chartDiv.removeChild(chartDiv.firstChild);
        }
        
      // Generate the series
      let series = [];
      for (let i = 0; i < 3; i++) {
        const data = [];
        for (let j = 0; j < 24; j++) {
          data.push({
            x: `${j}h`,
            y: 0
          });
        }
        series.push({
          name: `J-${i}`,
          data
        });
      }

      // Gaussian distribution around 23h on J-2
      for (let i = 0; i < 24; i++) {
        series[2].data[i].y = Math.round(10 * Math.exp(-Math.pow(i - 23, 2) / 2));
      }

      // Gaussian distribution around 20h on J-1
      for (let i = 0; i < 24; i++) {
        series[1].data[i].y = Math.round(10 * Math.exp(-Math.pow(i - 20, 2) / 2));
      }
      series[1].data[5].y = 2;
      series[1].data[12].y = 4;
      series[1].data[13].y = 3;
      series[1].data[14].y = 10;
      series[1].data[15].y = 5;

      // Gaussian distribution around 17h on J
      for (let i = 0; i < 18; i++) {
        series[0].data[i].y = Math.round(10 * Math.exp(-Math.pow(i - 17, 2) / 2));
      }
      series[0].data[17].y = 30;
      series[0].data[16].y = 20;
      series[0].data[15].y = 10;

      var options = {
        series: series,
        chart: {
          height: 200,
          type: 'heatmap',
          fontSize: '15px',
          fontFamily: "Sora",
          fontWeight: 800,
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#ff9600"]
      };
      var chart = new ApexCharts(document.querySelector("#submissionChart"), options);
      chart.render();
    }, []);

    // Middleware
    const { user } = useUser();
    const navigate = useNavigate();
    useEffect(() => {
      if (!user || user.rank != 'admin') {
        navigate('/');
      }
    }, []);
  
    return (
      <Box minH="100vh" bg={"white"}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        
        <Box ml={{ base: 0, md: 60 }} p="10" color={"#4b4b4b"}>
          <Text mb={10} fontSize={"3xl"} fontWeight={800} cursor={'default'}>Statistiques des Challenges</Text>
          
          <Text mb={5} color={"#717171"} fontSize={"2xl"} fontWeight={"bold"}>Distribution des points par Challenge</Text>

          <Box width={"100%"} height={"300px"}>
            <ResponsiveContainer>
              <BarChart
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }} 
                data={challengesMarks}>
                <XAxis dataKey="name" stroke="#cc3c3c" />
                <YAxis />
                <RechartsTooltip />
                <CartesianGrid stroke="#ccc" strokeDasharray="10 10" />
                <Bar dataKey="c0" fill="#ffc6c7" barSize={30} />
                <Bar dataKey="c1" fill="#ffb2b2" barSize={30} />
                <Bar dataKey="c2" fill="#f5a4a4" barSize={30} />
                <Bar dataKey="c3" fill="#ff7878" barSize={30} />
                <Bar dataKey="c4" fill="#ff4b4b" barSize={30} />
                <Bar dataKey="c5" fill="#cc3c3c" barSize={30} />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          <Text mt={10} mb={5} color={"#717171"} fontSize={"2xl"} fontWeight={"bold"}>Statistiques individuelles</Text>

          <Select size={"lg"} border={"2pt solid #e5e5e5"}>
            <option value='option0'>Challenge 0: Algorithmes, Codage des Entiers, et Premiers Pas en C</option>
            <option value='option1'>Challenge 1: Instructions Itératives</option>
            <option value='option2'>Challenge 2 : Boucles & Invariants Graphiques</option>
            <option value='option3'>Challenge 3 : Tableaux et Invariants Graphiques</option>
            <option value='option4'>Challenge 4 : Modules & Invariants Graphiques</option>
            <option value='option5'>Challenge 5 : Pointeurs & Allocation Dynamique</option>
          </Select>

          <Grid border={"2pt solid #e5e5e5"} templateColumns="repeat(2, 1fr)" gap={6} mt={5}>
            <Box p={5} borderRight={"2pt solid #e5e5e5"}>
              <Flex>
                <Image me={4} src={pen} width={"35px"} />
                <Box>
                  <Text fontSize={"xl"} fontWeight={"bold"}>Moyenne générale</Text>
                  <Text fontSize={"md"} fontWeight={"normal"}>14/20</Text>
                </Box>
              </Flex>
            </Box>
            <Box p={5}>
              <Flex>
                <Image me={4} src={lightning} height={"35px"} mt={2} />
                <Box>
                  <Text fontSize={"xl"} fontWeight={"bold"}>Participation</Text>
                  <Text fontSize={"md"} fontWeight={"normal"}>80%</Text>
                </Box>
              </Flex>
            </Box>
          </Grid>

          <Box border={"2pt solid #e5e5e5"} borderTop={0} p={5}>
            <Flex mt={2} justifyContent={"space-between"}>
              <HStack>
                <Image src={check_img} height={"15px"} />
                <Tooltip label="Ont soumis le challenge">
                  <Text fontSize={"sm"}>140 soumissions</Text>
                </Tooltip>
              </HStack>
              <HStack>
                <Image src={late_img} height={"15px"} />
                <Tooltip label="N'ont pas soumis le challenge">
                  <Text fontSize={"sm"}>6 oublis</Text>
                </Tooltip>
              </HStack>
              <HStack>
                <Image src={timespent_img} height={"15px"} />
                <Tooltip label="Temps passé sur le challenge">
                  <Text fontSize={"sm"}>35min en moyenne</Text>
                </Tooltip>
              </HStack>
              <HStack>
                <i className="em-svg em-black_joker"></i>
                <Tooltip label="Jokers utilisés">
                  <Text fontSize={"sm"}>0 jokers</Text>
                </Tooltip>
              </HStack>
            </Flex>
          </Box>

          <Text mt={5} mb={5} color={"#afafaf"} fontSize={"xl"} fontWeight={"bold"}>Distribution des notes</Text>

          <Box width={"100%"} height={"250px"}>
            <ResponsiveContainer>
              <BarChart
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }} 
                data={challengeDistro}>
                <XAxis dataKey="name" stroke="#cc7800" />
                <YAxis />
                <RechartsTooltip />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Bar dataKey="students" fill="#ff9600" barSize={30} />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          <Text mt={5} color={"#afafaf"} fontSize={"xl"} fontWeight={"bold"}>Heure de soumission</Text>
          
          <Box width={"100%"} height={"200px"}>
            <Box id="submissionChart" />
          </Box>

          <Box height={"800px"} />
          
        </Box>
      </Box>
    );
  }
  export default Challenges;