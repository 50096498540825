import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import {
  FiMenu,
  FiChevronDown,
} from 'react-icons/fi';
import { faGears, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUser } from '../types';
import { useEffect, useState } from 'react';
import { getUserData } from '../API/userData';
import { useUser } from '../UserContext';
import Cookies from 'js-cookie';

interface MobileProps extends FlexProps {
    onOpen: () => void;
    full?: boolean;
}
export const MobileNav = ({ onOpen, full=false, ...rest }: MobileProps) => {
    const [userData, setUserData] = useState<IUser | null>(null);
    const [loaded, setLoaded] = useState(false);
    const { user } = useUser();

    useEffect(() => {
      getUserData().then((response) => {
        setUserData(response.data);
        setLoaded(true);
      });

      // Liste to event UPDATE_USER_DATA
      window.addEventListener('UPDATE_USER_DATA', update_data);
    }, []);

    const update_data = () => {
      getUserData().then((response) => {
        setUserData(response.data);
      });
    }

    const logout = () => {
      Cookies.remove('jwt_cafe');
      window.location.href = '/';
    }

    return (
      <Flex
        ml={{ base: 0, md: full ? 0 : 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={"white"}
        color={"#4b4b4b"}
        borderBottomWidth="3px"
        borderBottomColor={"#e5e5e5"}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        {...rest}>

        {!full && 
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
            color={"white"}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu />}
          />
        }
  
        <Text
          display={{ base: 'flex', md: 'none' }}
          fontSize={{ base: 'xl', md: '2xl' }}
          color={"#ff4b4b"}
          fontWeight={900}>
          Student Tracker
        </Text>
  
        <HStack spacing={6}>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}>
                <HStack>
                  <Avatar
                    size={'sm'}
                    src={
                      ''
                    }
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2">
                    <Text fontSize="sm" fontWeight={800}>{user?.firstname} {user?.lastname}</Text>
                    <Text fontSize="xs" color="gray.600" fontWeight={600}>
                      {user?.rank == 'admin' ? 'Professeur·e' : 'Étudiant·e'}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                zIndex={101}
                bg={"white"}
                borderColor={"#e5e5e5"}
                borderWidth={2}
                color={"black"}>
                <MenuItem _hover={{backgroundColor:"#ddf4ff"}} onClick={logout} bg={"white"} icon={<FontAwesomeIcon icon={faRightFromBracket} />}>Déconnexion</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    );
  };