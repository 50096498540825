import { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { IChallengeSummary } from "../types";

export const getChallengesSummaries = async (matricule:string): Promise<AxiosResponse<IChallengeSummary[]>> => {
	try {
		const status: AxiosResponse<IChallengeSummary[]> = await API.get(
            `${API_URL}/challenges/summaries/${matricule}`
        );

        return status;
	} catch (error) {
		throw error;
	}
};